import React, { useEffect, useState, useMemo, useCallback } from 'react'
import line192svg from '../../assets/svg/line-19-2.svg'
import Assessment from '../../components/Assessment/Assessment'
import MessageCard from '../../components/MessageCard/MessageCard'
import Button from '../../components/Button/Button'
import { useNavigate, useParams } from 'react-router'
import { GET_ASSESSMENT_PROGRESS, GET_QUESTION } from '../../api/graphql'
import { useQuery } from '@apollo/client'
import { useUser } from '../../auth/AuthState'
import Layout from '../../layouts/Layout'
import { USER_ACTIVITY_STATUS } from '../../utils/common'
import { useUpdateUserActivity } from '../../hooks/useUpdateUserActivity'

const InstructorAssessment = () => {
  const [isAssessmentComplete, setIsAssessmentComplete] = useState(false)
  const [isAssessmentSuccess, setIsAssessmentSuccess] = useState(true)
  const [requestEvalution, setRequestEvalution] = useState(false)
  const [progressData, setProgressData] = useState([])
  const [courseName, setCourseName] = useState('')
  const [questions, setQuestions] = useState([])
  const navigate = useNavigate()
  const { updateUserActivity } = useUpdateUserActivity()

  const { id } = useParams()
  const {
    userState: { user },
  } = useUser()

  const { loading, refetch } = useQuery(GET_QUESTION, {
    variables: { id: +id },
  })
  const { refetch: refetchProgress } = useQuery(GET_ASSESSMENT_PROGRESS, {
    variables: {
      userId: user?.id,
      courseId: +id,
    },
  })

  const handleNext = useCallback(async () => {
    const courseTag = courseName?.includes('Adult')
      ? 'AdultInstructorCandidate'
      : 'YouthInstructorCandidate'
    navigate(`/instructor-registration`, {
      state: {
        courseId: +id,
      },
    })

    await updateUserActivity(+id, user?.id, USER_ACTIVITY_STATUS.SCORE)
  }, [courseName, navigate])

  const getQuestionCallback = useCallback(async () => {
    const questionData = await refetch({ id: +id })
    const pData = await refetchProgress({
      userId: user?.id,
      courseId: +id,
    })
    setProgressData(pData?.data?.GetAssessmentProgress?.data)
    setCourseName(questionData?.data?.GetQuestionByCourse?.data[0]?.course.name)
    setQuestions(questionData?.data?.GetQuestionByCourse?.data)
  }, [id, refetch, refetchProgress, JSON.stringify(user)])

  useEffect(() => {
    getQuestionCallback()
  }, [getQuestionCallback])

  const ThankYouMessage = useMemo(
    () => (
      <MessageCard
        title="Thank You"
        showLine={true}
        messageData={
          'Thank you for your interest in becoming a MHFA Instructor. Please click next to continue your journey to becoming an MHFA Instructor.'
        }
      >
        <Button
          text="Next"
          style={{ marginTop: '2rem', marginBottom: '1rem' }}
          onClick={handleNext}
        />
      </MessageCard>
    ),
    [handleNext]
  )

  const RequestReevaluation = useMemo(
    () => (
      <div className="container ">
        <div className="row d-flex justify-content-center">
          <MessageCard
            title="Thank You for your submission"
            showLine={true}
            messageData={`Thank you for your interest in becoming a Mental Health First Aid Instructor. Unfortunately, we are unable to advance your application at this time. You may reapply in 90 days. In the meantime, we encourage you to consider taking another First Aider course, attending a Becoming a MHFA Instructor webinar   and reviewing our website to learn more about the <a href="">role of a MHFA Instructor</a>. Try after 90 days. <br />If you have any questions please do not hesitate to reach out to us at <a href="mailto:mhfaapplications@thenationalcouncil.org">mhfaapplications@thenationalcouncil.org</a>.`}
            className="col-lg-5  d-flex flex-column align-items-center justify-content-center cardDiv  p-4"
          >
            <Button
              text="Back to Home"
              onClick={() => navigate('/instructor-application')}
              style={{
                marginTop: '2rem',
                marginBottom: '1rem',
                width: '336px',
              }}
            />
          </MessageCard>
        </div>
      </div>
    ),
    [setRequestEvalution]
  )

  const AssessmentPage = useMemo(
    () => (
      <Assessment
        title="Assessment"
        questions={questions}
        setIsAssessmentComplete={setIsAssessmentComplete}
        setIsAssessmentSuccess={setIsAssessmentSuccess}
        course_id={id}
        questionLoading={loading}
        progressData={progressData}
      />
    ),
    [
      questions,
      id,
      loading,
      setIsAssessmentComplete,
      setIsAssessmentSuccess,
      progressData,
    ]
  )

  return (
    <>
      <Layout title={'INSTRUCTOR APPLICATION'}>
        <p className="ptext mt-2">
          These 96 questions are to evaluate the characteristics that are important to be an MHFA Instructor. As you consider each item, it is important to: Respond honestly. Go with your initial reaction as it often best reflects your approach. Respond to each item, even those that may seem different. Proceed at a brisk pace while giving some thought to each item. Use all five response options (including Strongly Disagree and Strongly Agree) as appropriate.
        </p>

        <div>
          <img className="line" alt="Line" src={line192svg} />
        </div>

        <div
          className=" d-flex justify-content-center  "
          style={{ marginTop: '20px' }}
        >
          {!isAssessmentComplete && AssessmentPage}

          {/* {isAssessmentComplete && isAssessmentSuccess && ThankYouMessage} */}

          {/* {isAssessmentComplete && !isAssessmentSuccess && RequestReevaluation} */}

          {/* {isAssessmentComplete &&
                  !isAssessmentSuccess &&
                  requestEvalution &&
                  Videocomponent} */}

          {/* {isAssessmentComplete &&
                  !isAssessmentSuccess &&
                  confimation &&
                  ConfirmationMessage} */}
        </div>
      </Layout>
    </>
  )
}
export default InstructorAssessment
