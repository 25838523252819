import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import client from "./config/apolloConfig";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import "./global.css";
import { AuthProvider } from "./auth/AuthState";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    //gtmId: 'YOUR_GTM_ID' // Replace 'YOUR_GTM_ID' with your actual GTM ID
    //gtmId: 'YOUR_GTM_ID' // Replace 'YOUR_GTM_ID' with your actual GTM ID
    gtmId: 'GTM-MXWRZ97Z'
};

TagManager.initialize(tagManagerArgs);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
