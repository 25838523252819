import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        const pageData = {
            event: 'pageview',
            page: location.pathname
        };

        TagManager.dataLayer({
            dataLayer: pageData
        });
    }, [location]);
};

export default usePageTracking;
