import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import BecomeInstructor from './pages/BecomeInstructor/BecomeInstructor'
import InstructorRegistration from './pages/InstructorRegistration/InstructorRegistration'
import InstructorProfile from './pages/InstructorProfile/InstructorProfile'
import Congratulation from './pages/InstructorRegistration/Congratulation'
import Enrollment from './pages/InstructorRegistration/Enrollment'

import AdminLogin from './pages/Admin/AdminLogin'
import AdminCandidateList from './pages/AdminCandidateList/AdminCandidateList'
import AdminCandidateView from './pages/AdminCandidateView/AdminCandidateView'
import AdminRequest from './pages/AdminRequest/AdminRequest'
import AdminRequestForm from './pages/AdminRequestForm/AdminRequestForm'
import AdminEditRequestForm from './pages/AdminEditRequestForm/AdminEditRequestForm'
import AdminTrainingForm from './pages/AdminTrainingForm/AdminTrainingForm'
import AdminEditTrainingForm from './pages/AdminEditTrainingForm/AdminEditTrainingForm'
import AdminRequestFormView from './pages/AdminRequestFormView/AdminRequestFormView'
import AdminTrainingView from './pages/AdminTrainingView/AdminTrainingView'

import FindCourse from './pages/FindCourse/FindCourse'
import InstructorApplication from './pages/InstructorApplication/InstructorApplication'
import CourseDetails from './pages/CourseDetails/CourseDetails'
import InstructorAssessment from './pages/InstructorAssessment/InstructorAssessment'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ProtectedRoute from './guards/ProtectedRoute'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import { AuthProvider, useUser } from './auth/AuthState'
import storage from './auth/storage'
import 'react-datepicker/dist/react-datepicker.css'
import ShopiftyIntegration from './components/ShopifyIntegration/ShopiftyIntegration'
import LearnerAssessment from './pages/LearnerAssessment/LearnerAssessment'
import api from './api/graphql'
import Spinner from './components/Spinner/Spinner'

import Request from './pages/Request/Request'
import RequestList from './pages/RequestList/RequestList'
import ScorePage from './pages/ScorePage/ScorePage'
import AdminSupport from './pages/AdminSupport/AdminSupport'
import AdminSupportSingle from './pages/AdminSupportSingle/AdminSupportSingle'
import AdminReports from './pages/AdminReports/AdminReports'
import AdminReportsSummary from './pages/AdminReportsSummary/AdminReportsSummary'
import usePageTracking from './usePageTracking'

// if (__DEV__) {  // Adds messages only in a dev environment
loadDevMessages()
loadErrorMessages()
// }

function App() {
  const { dispatch } = useUser()
  const [loadUserData, setLoadUserData] = useState(true)

  const loadData = async () => {
    const userData = localStorage.getItem('userData')
    const isPasswordVerify = localStorage.getItem('isPasswordVerify')
    const email = localStorage.getItem('emailKey')
    const userType = localStorage.getItem('userType')
    dispatch({ type: 'USER_DATA', payload: JSON.parse(userData) })
    dispatch({ type: 'VERIFY_USER', payload: isPasswordVerify ? true : false })
    dispatch({ type: 'EMAIL', payload: email })
    dispatch({ type: 'USER_TYPE', payload: userType })
    if (email && userType !== 'admin') {
      const userLMS = await api.checkLMSUser(email) //check LMS User
      dispatch({
        type: 'CHECK_LMS',
        payload: userLMS?.CheckLMSUser?.success,
      })
    }
    setLoadUserData(false)
  }

  useEffect(() => {
    loadData()
  }, [])
  
  usePageTracking(); 

  if (loadUserData) {
    return (
      <>
        <div className="container mt-5">
          <div className="row d-flex justify-content-center">
            <Spinner sx={{}} />
          </div>
        </div>
      </>
    )
  }
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<BecomeInstructor />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/request" element={<Request />} />
        <Route
          path="/request/request-list"
          element={
            <ProtectedRoute>
              <RequestList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/candidate/view"
          element={
            <ProtectedRoute>
              <AdminCandidateView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/candidate-list"
          element={
            <ProtectedRoute>
              <AdminCandidateList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/request/new-request"
          element={
            <ProtectedRoute>
              <AdminRequestForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/request/edit-request"
          element={
            <ProtectedRoute>
              <AdminEditRequestForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/request/edit-request"
          element={
            <ProtectedRoute>
              <AdminEditRequestForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/request/training-form"
          element={
            <ProtectedRoute>
              <AdminTrainingForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/request/training-form"
          element={
            <ProtectedRoute>
              <AdminTrainingForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/request/edit-training"
          element={
            <ProtectedRoute>
              <AdminEditTrainingForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/request/edit-training"
          element={
            <ProtectedRoute>
              <AdminEditTrainingForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/request/view-request"
          element={
            <ProtectedRoute>
              <AdminRequestFormView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/request/view-request"
          element={
            <ProtectedRoute>
              <AdminRequestFormView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/request/view-training"
          element={
            <ProtectedRoute>
              <AdminTrainingView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/request/view-training"
          element={
            <ProtectedRoute>
              <AdminTrainingView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/request"
          element={
            <ProtectedRoute>
              <AdminRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path="/instructor-application"
          element={
            <ProtectedRoute>
              <InstructorApplication />
            </ProtectedRoute>
          }
        />
        <Route
          path="/instructor-registration"
          element={
            <ProtectedRoute>
              <InstructorRegistration />
            </ProtectedRoute>
          }
        />
        Enrollment
        <Route
          path="/enrollment"
          element={
            <ProtectedRoute>
              <Enrollment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/score"
          element={
            <ProtectedRoute>
              <ScorePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Congratulation"
          element={
            <ProtectedRoute>
              <Congratulation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/find-course"
          element={
            <ProtectedRoute>
              <FindCourse />
            </ProtectedRoute>
          }
        />
        <Route
          path="/instructor-assessment/:id"
          element={
            <ProtectedRoute>
              <InstructorAssessment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/instructor-profile"
          element={
            <ProtectedRoute>
              <InstructorProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/integration-process"
          element={
            <ProtectedRoute>
              <ShopiftyIntegration />
            </ProtectedRoute>
          }
        />
        <Route
          path="/learner-application"
          element={
            <ProtectedRoute>
              <LearnerAssessment />
            </ProtectedRoute>
          }
        />
        <Route path="/course-details" element={<CourseDetails />} />
        <Route
          path="/admin/support"
          element={
            <ProtectedRoute>
              <AdminSupport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/support/:supportId"
          element={
            <ProtectedRoute>
              <AdminSupportSingle />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/reports/detailed"
          element={
            <ProtectedRoute>
              <AdminReports />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/admin/reports/summary"
          element={
            <ProtectedRoute>
              <AdminReportsSummary />
            </ProtectedRoute>
          }
        /> */}
        {/* <Rout e path="/register" element={<Register />} /> */}
      </Routes>
    </>
  )
}

export default App
